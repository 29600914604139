exports.components = {
  "component---node-modules-truemark-gatsby-theme-effortless-blog-src-templates-blog-list-tsx": () => import("./../../../node_modules/@truemark/gatsby-theme-effortless-blog/src/templates/blog-list.tsx" /* webpackChunkName: "component---node-modules-truemark-gatsby-theme-effortless-blog-src-templates-blog-list-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-case-studies-flexonet-jsx": () => import("./../../../src/pages/case-studies/flexonet.jsx" /* webpackChunkName: "component---src-pages-case-studies-flexonet-jsx" */),
  "component---src-pages-case-studies-trivia-day-jsx": () => import("./../../../src/pages/case-studies/trivia-day.jsx" /* webpackChunkName: "component---src-pages-case-studies-trivia-day-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-hire-resources-jsx": () => import("./../../../src/pages/hire/resources.jsx" /* webpackChunkName: "component---src-pages-hire-resources-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-resume-anil-index-jsx": () => import("./../../../src/pages/resume/anil/index.jsx" /* webpackChunkName: "component---src-pages-resume-anil-index-jsx" */),
  "component---src-pages-resume-mausam-index-jsx": () => import("./../../../src/pages/resume/mausam/index.jsx" /* webpackChunkName: "component---src-pages-resume-mausam-index-jsx" */),
  "component---src-pages-resume-parash-ojha-index-jsx": () => import("./../../../src/pages/resume/parash-ojha/index.jsx" /* webpackChunkName: "component---src-pages-resume-parash-ojha-index-jsx" */),
  "component---src-pages-resume-prabin-index-jsx": () => import("./../../../src/pages/resume/prabin/index.jsx" /* webpackChunkName: "component---src-pages-resume-prabin-index-jsx" */),
  "component---src-pages-resume-prakash-index-jsx": () => import("./../../../src/pages/resume/prakash/index.jsx" /* webpackChunkName: "component---src-pages-resume-prakash-index-jsx" */),
  "component---src-pages-services-design-jsx": () => import("./../../../src/pages/services/design.jsx" /* webpackChunkName: "component---src-pages-services-design-jsx" */),
  "component---src-pages-services-digital-marketing-jsx": () => import("./../../../src/pages/services/digital-marketing.jsx" /* webpackChunkName: "component---src-pages-services-digital-marketing-jsx" */),
  "component---src-pages-services-maintenance-tests-jsx": () => import("./../../../src/pages/services/maintenance/tests.jsx" /* webpackChunkName: "component---src-pages-services-maintenance-tests-jsx" */),
  "component---src-pages-services-maintenance-upgrade-jsx": () => import("./../../../src/pages/services/maintenance/upgrade.jsx" /* webpackChunkName: "component---src-pages-services-maintenance-upgrade-jsx" */),
  "component---src-pages-technologies-gatsby-js-jsx": () => import("./../../../src/pages/technologies/gatsby-js.jsx" /* webpackChunkName: "component---src-pages-technologies-gatsby-js-jsx" */),
  "component---src-pages-technologies-react-js-jsx": () => import("./../../../src/pages/technologies/react-js.jsx" /* webpackChunkName: "component---src-pages-technologies-react-js-jsx" */),
  "component---src-pages-technologies-ruby-on-rails-jsx": () => import("./../../../src/pages/technologies/ruby-on-rails.jsx" /* webpackChunkName: "component---src-pages-technologies-ruby-on-rails-jsx" */),
  "component---src-pages-technologies-spree-jsx": () => import("./../../../src/pages/technologies/spree.jsx" /* webpackChunkName: "component---src-pages-technologies-spree-jsx" */),
  "component---src-pages-technologies-upgrade-your-application-jsx": () => import("./../../../src/pages/technologies/upgrade-your-application.jsx" /* webpackChunkName: "component---src-pages-technologies-upgrade-your-application-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-trainee-jsx": () => import("./../../../src/pages/trainee.jsx" /* webpackChunkName: "component---src-pages-trainee-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2018-12-24-three-questions-about-mvp-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2018-12-24-three-questions-about-mvp.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2018-12-24-three-questions-about-mvp-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-08-12-starting-a-tech-startup-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-08-12-starting-a-tech-startup.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-08-12-starting-a-tech-startup-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-08-15-finding-a-great-team-for-startup-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-08-15-finding-a-great-team-for-startup.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-08-15-finding-a-great-team-for-startup-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-08-19-pricing-model-for-software-development-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-08-19-pricing-model-for-software-development.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-08-19-pricing-model-for-software-development-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-08-29-understanding-scrum-methodology-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-08-29-understanding-scrum-methodology.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-08-29-understanding-scrum-methodology-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-09-05-why-you-need-a-great-specification-for-your-projet-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-09-05-why-you-need-a-great-specification-for-your-projet.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-09-05-why-you-need-a-great-specification-for-your-projet-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-09-12-web-design-trends-2019-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-09-12-web-design-trends-2019.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-09-12-web-design-trends-2019-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-09-19-website-development-team-structure-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-09-19-website-development-team-structure.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-09-19-website-development-team-structure-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-09-26-how-to-estimate-a-project-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-09-26-how-to-estimate-a-project.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-09-26-how-to-estimate-a-project-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-03-before-accepting-fixed-price-model-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-10-03-before-accepting-fixed-price-model.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-03-before-accepting-fixed-price-model-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-10-why-every-business-needs-seo-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-10-10-why-every-business-needs-seo.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-10-why-every-business-needs-seo-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-10-why-user-experience-is-important-for-website-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-10-10-why-user-experience-is-important-for-website.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-10-why-user-experience-is-important-for-website-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-24-top-12-google-chrome-extensions-we-started-using-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-10-24-top-12-google-chrome-extensions-we-started-using.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-24-top-12-google-chrome-extensions-we-started-using-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-31-dieter-rams-10-principles-of-good-design-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-10-31-dieter-rams-10-principles-of-good-design.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-10-31-dieter-rams-10-principles-of-good-design-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-11-07-how-big-should-your-team-be-before-hiring-a-manager-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-11-07-how-big-should-your-team-be-before-hiring-a-manager.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-11-07-how-big-should-your-team-be-before-hiring-a-manager-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-11-14-8-web-trends-2020-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-11-14-8-web-trends-2020.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-11-14-8-web-trends-2020-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-11-21-8-must-have-chrome-extensions-for-designers-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-11-21-8-must-have-chrome-extensions-for-designers.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-11-21-8-must-have-chrome-extensions-for-designers-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-11-28-how-to-convince-executives-to-outsource-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-11-28-how-to-convince-executives-to-outsource.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-11-28-how-to-convince-executives-to-outsource-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-12-05-how-to-market-an-app-successfully-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-12-05-how-to-market-an-app-successfully.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-12-05-how-to-market-an-app-successfully-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-12-12-why-digital-marketing-is-important-for-business-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-12-12-why-digital-marketing-is-important-for-business.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-12-12-why-digital-marketing-is-important-for-business-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-12-19-how-to-increase-traffic-on-website-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-12-19-how-to-increase-traffic-on-website.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-12-19-how-to-increase-traffic-on-website-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-12-26-why-product-manager-is-trending-over-project-manager-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2019-12-26-why-product-manager-is-trending-over-project-manager.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2019-12-26-why-product-manager-is-trending-over-project-manager-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-02-why-people-neglect-maintenance-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-01-02-why-people-neglect-maintenance.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-02-why-people-neglect-maintenance-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-09-do-you-really-need-a-technical-cofounder-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-01-09-do-you-really-need-a-technical-cofounder.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-09-do-you-really-need-a-technical-cofounder-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-16-reset-password-react-rails-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-01-16-reset-password-react-rails.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-16-reset-password-react-rails-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-23-how-to-keep-investors-happy-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-01-23-how-to-keep-investors-happy.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-23-how-to-keep-investors-happy-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-30-okr-framework-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-01-30-okr-framework.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-01-30-okr-framework-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-02-06-ways-small-business-can-use-social-media-marketing-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-02-06-ways-small-business-can-use-social-media-marketing.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-02-06-ways-small-business-can-use-social-media-marketing-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-02-13-how-not-to-start-a-tech-company-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-02-13-how-not-to-start-a-tech-company.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-02-13-how-not-to-start-a-tech-company-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-02-20-how-to-outsource-mvp-successfully-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-02-20-how-to-outsource-mvp-successfully.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-02-20-how-to-outsource-mvp-successfully-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-02-27-best-saas-pricing-models-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-02-27-best-saas-pricing-models.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-02-27-best-saas-pricing-models-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-03-05-ways-to-validate-a-business-idea-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-03-05-ways-to-validate-a-business-idea.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-03-05-ways-to-validate-a-business-idea-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-03-12-how-to-manage-heavy-workload-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-03-12-how-to-manage-heavy-workload.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-03-12-how-to-manage-heavy-workload-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-03-19-how-to-manage-development-team-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-03-19-how-to-manage-development-team.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-03-19-how-to-manage-development-team-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-03-26-7-web-design-mistakes-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-03-26-7-web-design-mistakes.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-03-26-7-web-design-mistakes-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-02-how-to-find-investors-for-your-startup-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-04-02-how-to-find-investors-for-your-startup.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-02-how-to-find-investors-for-your-startup-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-09-ways-to-get-b-2-b-clients-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-04-09-ways-to-get-b2b-clients.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-09-ways-to-get-b-2-b-clients-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-16-why-employee-engagement-is-important-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-04-16-why-employee-engagement-is-important.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-16-why-employee-engagement-is-important-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-23-performance-management-and-development-teams-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-04-23-performance-management-and-development-teams.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-23-performance-management-and-development-teams-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-30-10-ways-to-deal-with-difficult-clients-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-04-30-10-ways-to-deal-with-difficult-clients.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-04-30-10-ways-to-deal-with-difficult-clients-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-05-07-challenges-every-software-developers-face-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-05-07-challenges-every-software-developers-face.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-05-07-challenges-every-software-developers-face-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-05-14-saas-pricing-startegies-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-05-14-saas-pricing-startegies.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-05-14-saas-pricing-startegies-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-05-21-ways-small-business-can-cut-costs-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-05-21-ways-small-business-can-cut-costs.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-05-21-ways-small-business-can-cut-costs-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-05-28-ways-to-secure-data-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-05-28-ways-to-secure-data.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-05-28-ways-to-secure-data-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-06-04-ux-design-tools-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-06-04-ux-design-tools.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-06-04-ux-design-tools-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-06-11-how-to-find-developers-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-06-11-how-to-find-developers.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-06-11-how-to-find-developers-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-06-18-why-is-content-marketing-important-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-06-18-why-is-content-marketing-important.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-06-18-why-is-content-marketing-important-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-06-25-must-have-tools-for-tech-startup-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-06-25-must-have-tools-for-tech-startup.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-06-25-must-have-tools-for-tech-startup-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-02-communicate-ideas-to-development-team-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-07-02-communicate-ideas-to-development-team.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-02-communicate-ideas-to-development-team-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-09-why-video-marketing-is-powerful-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-07-09-why-video-marketing-is-powerful.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-09-why-video-marketing-is-powerful-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-16-founder-and-web-development-team-structure-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-07-16-founder-and-web-development-team-structure.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-16-founder-and-web-development-team-structure-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-23-ways-to-increase-productivity-working-from-home-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-07-23-ways-to-increase-productivity-working-from-home.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-23-ways-to-increase-productivity-working-from-home-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-30-importance-of-project-management-tools-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-07-30-importance-of-project-management-tools.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-07-30-importance-of-project-management-tools-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-08-06-how-to-run-a-great-virtual-meeting-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-08-06-how-to-run-a-great-virtual-meeting.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-08-06-how-to-run-a-great-virtual-meeting-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-08-13-ways-to-manage-your-remote-team-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-08-13-ways-to-manage-your-remote-team.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-08-13-ways-to-manage-your-remote-team-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-08-20-what-businesses-are-flourishing-during-pandemic-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-08-20-what-businesses-are-flourishing-during-pandemic.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-08-20-what-businesses-are-flourishing-during-pandemic-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-08-27-ways-to-reach-audience-online-during-covid-19-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-08-27-ways-to-reach-audience-online-during-covid-19.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-08-27-ways-to-reach-audience-online-during-covid-19-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-09-03-how-much-does-it-cost-to-build-an-app-like-fiverr-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2020-09-03-how-much-does-it-cost-to-build-an-app-like-fiverr.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2020-09-03-how-much-does-it-cost-to-build-an-app-like-fiverr-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2021-12-09-how-much-does-it-cost-to-build-app-like-uber-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2021-12-09-how-much-does-it-cost-to-build-app-like-uber.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2021-12-09-how-much-does-it-cost-to-build-app-like-uber-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2021-12-16-ux-principles-every-designer-should-know-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2021-12-16-ux-principles-every-designer-should-know.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2021-12-16-ux-principles-every-designer-should-know-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2021-12-23-web-design-trends-in-2021-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2021-12-23-web-design-trends-in-2021.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2021-12-23-web-design-trends-in-2021-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2021-12-30-how-much-does-it-cost-to-build-an-app-like-netflix-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2021-12-30-how-much-does-it-cost-to-build-an-app-like-netflix.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2021-12-30-how-much-does-it-cost-to-build-an-app-like-netflix-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-01-06-how-much-does-it-cost-to-build-food-delivery-app-like-uber-eats-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2022-01-06-how-much-does-it-cost-to-build-food-delivery-app-like-uber-eats.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-01-06-how-much-does-it-cost-to-build-food-delivery-app-like-uber-eats-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-01-13-best-wireframe-tools-for-web-design-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2022-01-13-best-wireframe-tools-for-web-design.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-01-13-best-wireframe-tools-for-web-design-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-01-20-android-vs-ios-which-platform-to-prioritize-first-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2022-01-20-android-vs-ios-which-platform-to-prioritize-first.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-01-20-android-vs-ios-which-platform-to-prioritize-first-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-01-27-why-are-wireframes-important-in-web-design-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2022-01-27-why-are-wireframes-important-in-web-design.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-01-27-why-are-wireframes-important-in-web-design-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-04-27-nft-marketplace-development-cost-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/posts/2022-04-27-nft-marketplace-development-cost.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-contents-posts-2022-04-27-nft-marketplace-development-cost-md" */),
  "component---src-templates-case-study-jsx-content-file-path-contents-case-studies-artlords-mdx": () => import("./../../../src/templates/case-study.jsx?__contentFilePath=/builds/truemark/truemark_blog/contents/case-studies/artlords.mdx" /* webpackChunkName: "component---src-templates-case-study-jsx-content-file-path-contents-case-studies-artlords-mdx" */),
  "component---src-templates-job-jsx": () => import("./../../../src/templates/job.jsx" /* webpackChunkName: "component---src-templates-job-jsx" */)
}

